<template id="temp">
  <!-- vue实例外创建 -->
  <div>
    <div class="detailContent" :data="detailsArray">
      <!-- 应用信息 -->
      <!-- <p class="detailTitle">应用信息</p>
      <div class="detailBox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">应用名称：</span>
            <span class="detailDesc">{{ detailsArray.appname }}</span>
          </div>detailDesc
          <div class="group">
            <span class="detailLabel">应用版本：</span>
            <span class="detailDesc">{{ detailsArray.versionname }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">应用包名：</span>
            <span class="detailDesc">{{ detailsArray.packagename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left">应用MD5：</span>
            <span class="detailDesc" style="display: block; float: left; text-align: justify">{{ detailsArray.udid }}</span>
          </div>
        </div>
      </div> -->
      <el-descriptions :title="$t('SecurityMonitor.RunInfoPage.AppInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.appName')">{{ detailsArray.appname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.AppVersion')">
          {{ detailsArray.versionname }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.AppPackageName')">{{ detailsArray.packagename }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.AppMD5')">{{ detailsArray.appMd5 }}</el-descriptions-item>
      </el-descriptions>

      <!-- 基本信息 -->
      <!-- <p class="detailTitle">基本信息</p>
      <div class="detailBox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel" style="display: block; float: left">设备名称：</span>

            <span class="detailDesc" :title="detailsArray.devicename" style="  white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                line-height: 10px;">9828dcbf78c33ff5a67f9dfd1242997d36cb18929828dcbf78c33ff5a67f9d</span>
          </div>
          <div class="group">
            <span class="detailLabel">姓名：</span>
            <span class="detailDesc">{{ detailsArray.loginname }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">用户部门：</span>
            <span :title="detailsArray.groupfullname" class="detailDesc" style="
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                line-height: 10px;
              ">{{ detailsArray.groupfullname }}</span>
          </div>
      <div class="group">
              <span class="detailLabel">设备厂商：</span>
              <span class="detailDesc">{{ detailsArray.operator }}</span>
            </div>
            <div class="group">
              <span class="detailLabel">合规状态：</span>
              <span class="detailDesc">{{ detailsArray.isDanger }}</span>
            </div>
          <div class="group">
            <span class="detailLabel">系统版本：</span>
            <span class="detailDesc">{{ detailsArray.osVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备IP：</span>
            <span class="detailDesc">{{ detailsArray.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">用户名：</span>
            <span class="detailDesc">{{ detailsArray.username }}</span>
          </div>

          <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detailDesc">{{ detailsArray.devicemodel }}</span>
          </div>
         <div class="group">
              <span class="detailLabel">设备越狱：</span>
              <span class="detailDesc"></span>
            </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left">上网方式：</span>
            <span class="detailDesc" style="display: block; float: left; text-align: justify">{{ detailsArray.netType }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left">设备IMEI：</span>
            <span class="detailDesc" style="display: block; float: left; text-align: justify">{{ detailsArray.imei }}</span>
          </div>
        </div>
      </div> -->
      <el-descriptions :title="$t('SecurityMonitor.RunInfoPage.BasicInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.deviceName')">{{ detailsArray.devicename }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.fullName')">{{ detailsArray.username }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.UserDepartment')">{{ detailsArray.groupfullname.substr(groupFullNameShowByIndex) }}</el-descriptions-item>
        <!-- <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.deviceManufacturer')">{{ detailsArray.operator }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.ComStatus')">{{ detailsArray.isDanger }}</el-descriptions-item> -->
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.systemversion')">{{ detailsArray.osVersion }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.DeviceIP')">{{ detailsArray.ip }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.userName')">{{ detailsArray.loginname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.EquipmentModel')">{{ detailsArray.devicemodel }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.InternetMethod')">{{ $filter.surfInterType(detailsArray.netType) }}</el-descriptions-item>
        <!-- <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.EquipmentIMEI')"><span v-if="detailsArray.imei">{{ detailsArray.imei }}</span> <span v-else> {{ 'N/A'}}</span></el-descriptions-item> -->
        <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac -->
        <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='Android'" :label="$t('public.IMEIAndroid')"><span v-if="detailsArray.imei">{{ detailsArray.imei }}</span> <span v-else> {{ 'N/A'}}</span></el-descriptions-item>
        <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='iOS'"  :label="$t('public.IMEIiOS')"><span v-if="detailsArray.imei">{{ detailsArray.imei }}</span> <span v-else> {{ 'N/A'}}</span></el-descriptions-item>
        <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='Linux'"  :label="$t('public.IMEIMac')"><span v-if="detailsArray.imei">{{ detailsArray.imei }}</span> <span v-else> {{ 'N/A'}}</span></el-descriptions-item>
        <el-descriptions-item  v-if="$common.getSystemName(detailsArray.osVersion)=='Windows'" :label="$t('public.IMEIMac')"><span v-if="detailsArray.imei">{{ detailsArray.imei }}</span> <span v-else> {{ 'N/A'}}</span></el-descriptions-item>
        <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='Unknown'" :label="$t('public.IMEIUnknown')"><span v-if="detailsArray.imei">{{ detailsArray.imei }}</span> <span v-else> {{ 'N/A'}}</span></el-descriptions-item>
      </el-descriptions>
      <!-- 位置信息 -->
      <!-- <p class="detailTitle">位置信息</p>
      <div class="detailBox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">国家：</span>
            <span class="detailDesc">{{ detailsArray.country }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">省份：</span>
            <span class="detailDesc">{{ detailsArray.province }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">城市：</span>
            <span class="detailDesc">{{ detailsArray.city }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">经纬度：</span>
            <span class="detailDesc" v-if="detailsArray.longitude == ''"></span>
            <span class="detailDesc" v-else>{{ detailsArray.longitude }},{{ detailsArray.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel" style="display: block; float: left">详细地址：</span>

          <span class="detailDesc" style="display: block; float: left; text-align: justify">{{ detailsArray.location }}</span>
        </div>
      </div> -->
      <el-descriptions :title="$t('SecurityMonitor.RunInfoPage.positionInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.country')">{{ detailsArray.country }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.province')">{{ detailsArray.province }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.city')">{{ detailsArray.city }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.latitudeLongitude')" v-if="detailsArray.longitude == '' && detailsArray.latitude == ''">N/A,N/A</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.latitudeLongitude')" v-if="detailsArray.longitude != '' && detailsArray.latitude != ''">{{ detailsArray.longitude }},{{ detailsArray.latitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.latitudeLongitude')" v-if="detailsArray.longitude == '' && detailsArray.latitude !== ''">N/A,{{ detailsArray.latitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.latitudeLongitude')" v-if="detailsArray.longitude != '' && detailsArray.latitude == ''">{{ detailsArray.longitude }},N/A</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.Address')">{{ detailsArray.location }}</el-descriptions-item>
      </el-descriptions>
      <!-- 风险信息 -->
      <!-- <p class="detailTitle">运行信息</p>
      <div class="detailBox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">启动次数：</span>
            <span class="detailDesc">{{ detailsArray.runcount+ "次" }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">产生时间：</span>
            <span class="detailDesc">{{
              detailsArray.uploadtime | dateformat
            }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">使用时长：</span>
            <span class="detailDesc">{{ formatDuring(detailsArray.runtime)  || 0 + "秒"}}</span>
          <span
              class="detailDesc"
              v-if="detailsArray.level == '3'"
              style="color: #d9001b"
              >高</span
            >
            <span
              class="detailDesc"
              v-if="detailsArray.level == '2'"
              style="color: #f59a23"
              >中</span
            >
            <span
              class="detailDesc"
              v-if="detailsArray.level == '1'"
              style="color: #00cdcd"
              >低</span
            >
          </div>
          <div class="group">
            <span class="detailLabel">上报时间：</span>
            <span class="detailDesc">{{
              detailsArray.createtime | dateformat
            }}</span>
          </div>
        </div>
      </div> -->
      <el-descriptions :title="$t('SecurityMonitor.RunInfoPage.RunInfo')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.numberStarts')">{{ detailsArray.runcount+ "次"  }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.generationTime')">{{detailsArray.createtime }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.UsageDuration')">{{  $common.formatDuring(detailsArray.runtime)  }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.RunInfoPage.ReportingTime')">{{  detailsArray.updatetime}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailsArray: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      groupFullNameShowByIndex: ''
    }
  },

  methods: {},

  created() {},

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  }
}
</script>
<style lang="scss" scoped>
.detailContent {
  width: 100%;
  // height: 469px !important;
  //overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }
  .detailBox {
    width: 100%;
    display: flex;
    font-size: 12px;
    .boxLeft {
      width: 50%;
      padding-left: 68px;
      line-height: 24px;
      .group {
        height: 24px;
        line-height: 24px;
        margin-top: 8px;
        .detailLabel {
          // width: 44%;
          text-align: left;
        }
        .detailDesc {
          word-break: break-all !important;
          word-wrap: break-word !important;
          width: 56%;
          display: inline-block;
        }
      }
    }
    .boxRight {
      width: 50%;
      .group {
        // height: 24px;
        line-height: 24px;
        margin-top: 8px;
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        text-align: left;
        .detailLabel {
          text-align: left;
        }
        .detailDesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }
  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;
    .group {
      height: 24px;
      line-height: 24px;
      margin-top: 8px;
      .detailLabel {
        height: 24px !important;
        line-height: 24px;
        text-align: left;
        padding-right: 5px;
        display: inline-block;
      }
      .detailDesc {
        word-break: break-all !important;
        word-wrap: break-word !important;
        width: 78%;
        display: inline-block;
        display: flex;
      }
    }
  }
}
::v-deep .el-descriptions__title {
  color: #606266;
  font-size: 14px;
}
::v-deep .el-descriptions__body {
  color: #606266;
  font-size: 12px;
  background-color: #fff;
  padding-left: 70px;
}
::v-deep .el-descriptions__title {
    font-size: 14px;
    font-weight: 700;
}
</style>
