<template>
  <div class="common-wrapper">
    <el-row :gutter="4" style="display: flex">
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          style="width: 100%"
          size="small"
          v-model="formInline.optionDate1"
          type="date"
          :placeholder="$t('SecurityMonitor.RunInfoPage.dataPlaceholder')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          style="width: 100%"
          size="small"
          v-model="formInline.optionDate2"
          type="date"
          :placeholder="$t('SecurityMonitor.RunInfoPage.dataPlaceholder')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <select-input
        :inputText="formInline.keywords"
        :options="devNameData"
        @selectSearchChange="selectSearchChange"
        @searchList="onSubmit"
      ></select-input>
      <!-- <el-col :span="3">
        <el-select style="width: 100%" v-model="formInline.devicValue" placeholder="应用名称" size="small" @change="changeSele">
          <el-option v-for="item in devNameData" :key="item.devicValue" :label="item.label" :value="item.label">
          </el-option>
        </el-select>
      </el-col> -->
      <!-- <el-col :span="3">
        <el-input style="width: 97%" size="small" v-model.trim="formInline.keywords" :placeholder="`请输入${placeholder}`" @keyup.enter.native="onSubmit('ser')"></el-input>
      </el-col> -->
      <!-- <el-button size="small" type="primary" @click="onSubmit('ser')">查询</el-button> -->
      <el-col :span="3">
        <el-button size="small" type="primary" @click="outreport">
          {{ $t("public.Derive") }}</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="4" class="elRow" style="position: relative">
      <div
        style="
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 5px;
          top: -32px;
          cursor: pointer;
        "
      >
        <!-- 弹出框 -->
        <el-popover
          placement="bottom"
          trigger="click"
          popper-class="LinkageTable"
        >
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="
                $t(`SecurityMonitor.RunInfoPage.Tablelinkage.${item.eng}`)
              "
              :checked="item.isChecked"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
              >{{
                $t(`SecurityMonitor.RunInfoPage.Tablelinkage.${item.name}`)
              }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="@/images/showConfig.png"
            alt=""
            style="width: 20px; height: 20px"
          />
        </el-popover>
      </div>
      <el-table
        size="mini"
        ref="myTable"
        :data="lists"
        fit
        tooltip-effect="dark"
        class="tableClass"
        stripe
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        :default-sort="{ prop: 'UPLOADTIME', order: 'ascending' }"
        @sort-change="sort"
      >
        <template>
          <el-table-column
            v-if="$filter.nameFilter(formThead, tradeSelections)"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              width="120"
              v-for="(item, index) in formThead"
              :key="index"
              :label="item.name"
            >
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        <el-table-column
          fixed
          width="160"
          :label="$t('SecurityMonitor.RunInfoPage.appName')"
          prop="APPNAME"
          :show-overflow-tooltip="true"
          v-if="tradeSelections[tradeSelections.length - 10].isChecked"
        >
          <template v-slot="{ row }">
            {{ row.appname }}
          </template>
        </el-table-column>
        <el-table-column
          fixed
          width="160"
          :label="$t('SecurityMonitor.RunInfoPage.appVersion')"
          prop="VERSIONNAME"
          :show-overflow-tooltip="true"
          v-if="tradeSelections[tradeSelections.length - 9].isChecked"
        >
          <template v-slot="{ row }">
            {{ row.versionname }}
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :label="$t('SecurityMonitor.RunInfoPage.deviceName')"
          prop="DEVICENAME"
          :show-overflow-tooltip="true"
          v-if="tradeSelections[tradeSelections.length - 8].isChecked"
        >
          <template v-slot="{ row }">
            {{ row.devicename }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('SecurityMonitor.RunInfoPage.userName')"
          width="160"
          prop="LOGINNAME"
          :show-overflow-tooltip="true"
          v-if="tradeSelections[tradeSelections.length - 7].isChecked"
        >
          <template v-slot="{ row }">
            {{ row.loginname }}
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.RunInfoPage.department')"
          prop="GROUPFULLNAME"
          v-if="tradeSelections[tradeSelections.length - 6].isChecked"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{
              row.groupfullname.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.RunInfoPage.numberStarts')"
          prop="RUNCOUNT"
          v-if="tradeSelections[tradeSelections.length - 5].isChecked"
        >
          <template v-slot="{ row }">
            {{ row.runcount + "次" }}
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.RunInfoPage.UsageDuration')"
          prop="RUNTIME"
          v-if="tradeSelections[tradeSelections.length - 4].isChecked"
        >
          <template v-slot="{ row }">
            {{ $common.formatDuring(row.runtime) }}
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.RunInfoPage.UsingTraffic')"
          prop="USEFLOW"
          v-if="tradeSelections[tradeSelections.length - 3].isChecked"
        >
          <template v-slot="{ row }">
            <span> {{ $common.formatFlow(row.useflow) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.RunInfoPage.ReportingTime')"
          prop="UPDATETIME"
          v-if="tradeSelections[tradeSelections.length - 2].isChecked"
        >
          <template v-slot="{ row }">
            {{ row.updatetime }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('public.Controls')">
          <template v-slot="{ row }">
            <span
              size="small"
              @click="details(row)"
              style="color: #d38a08; cursor: pointer"
              >{{ $t("public.Details") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        :page="page.currentPage"
        :limit="page.pageSize"
        :total="page.total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('SecurityMonitor.RunInfoPage.details')"
      :dialogVisible="outerVisible"
      :flagbtn="false"
      @determine="handleClose"
      :num="'470px'"
    >
      <runDetail :detailsArray="detailsArray"></runDetail>
    </Eldialog>
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import runDetail from "./components/runDetail.vue";
import Eldialog from "@/components/elDialog/index.vue";
import pagination from "../../components/pagination/page";
export default {
  components: { selectInput, pagination, runDetail, Eldialog },
  data() {
    return {
      tradeSelections: [
        { eng: "appname", name: "应用名称", isChecked: true, flag: true },
        { eng: "versionname", name: "应用版本", isChecked: true, flag: true },
        { eng: "devicename", name: "设备名称", isChecked: true, flag: true },
        { eng: "loginname", name: "用户名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: false, flag: false },
        { eng: "runcount", name: "启动次数", isChecked: true, flag: false },
        { eng: "runtime", name: "使用时长", isChecked: true, flag: false },
        { eng: "useflow", name: "使用流量", isChecked: true, flag: false },
        { eng: "uploadtime", name: "上报时间", isChecked: true, flag: true },
        { eng: "operator", name: "操作", isChecked: true, flag: true },
      ],
      groupFullNameShowByIndex: "", //用户部门截取的下标
      pickerOptions1: {},
      pickerOptions0: {},
      tradeSelections1: [],
      page: {
        total: 0, //总条数
        pageSize: 10, //每页条数,  默认10条
        orderRule: "DESC", //排序规则ASC、DESC
        currentPage: 1,
        orderColume: "uploadtime", //排序字段
      },
      lists: [],
      number: 0,
      cityarrs: [],
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      seleShift: "", // 选中下拉框转译
      formInline: {
        keywords: "", // 设备名称
        devicValue: "应用名称", //下拉
        optionDate1: "",
        optionDate2: "", // $t('SecurityMonitor.RunInfoPage.dataPlaceholder')
      },
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      placeholder: "应用名称",
      devNameData: [
        {
          value: "appname",
          label: "应用名称",
        },
        {
          value: "devicename",
          label: "设备名称",
        },
        {
          value: "loginname",
          label: "用户名",
        },
        {
          value: "groupFullName",
          label: "部门",
        },
      ],
      key: 1, //table  key
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      number: 0,
      lists: [],
      detailsArray: [], //详情
      outerVisible: false, // 详情弹框
      tradeSelections: [
        { eng: "appname", name: "应用名称", isChecked: true, flag: true },
        { eng: "versionname", name: "应用版本", isChecked: true, flag: true },
        { eng: "devicename", name: "设备名称", isChecked: true, flag: true },
        { eng: "loginname", name: "用户名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: false, flag: false },
        { eng: "runcount", name: "启动次数", isChecked: true, flag: false },
        { eng: "runtimeStr", name: "使用时长", isChecked: true, flag: false },
        { eng: "useflow", name: "使用流量", isChecked: true, flag: false },
        { eng: "uploadtime", name: "上报时间", isChecked: true, flag: true },
        { eng: "operator", name: "操作", isChecked: true, flag: true },
      ],
      tradeSelections1: [],
      groupFullNameShowByIndex: "", //用户部门截取的下标
    };
  },
  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },
  created() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    //获取列表
    if (this.$route.query.name) {
      this.selectVal.deviceName = "appname";
      this.selectVal.InputText = this.$route.query.name;
      this.getRunInfoList(this.selectVal);
    }
    if (this.$route.query.fl == true) {
      this.Day();
      this.getRunInfoList(this.selectVal);
    } else if (this.$route.query.fl == false) {
      this.Mounth();
      this.getRunInfoList(this.selectVal);
    } else if (this.$route.query.card == true) {
      this.card();
      this.getRunInfoList(this.selectVal);
    } else if (this.$route.query.cli == true) {
      this.MingTime();
      this.getRunInfoList(this.selectVal);
    } else {
      this.formInline.optionDate1 = this.$common.getAnyMonth("-", -1);
      this.formInline.optionDate2 = this.$common.getDate();
      this.getRunInfoList(this.selectVal);
    }
  },
  updated() {
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      // myTable是表格的ref属性值

      this.$refs.myTable.doLayout();
    }
  },
  methods: {
    selectSearchChange(val) {
      this.seleShift = val.deviceName;
      this.formInline.keywords = val.InputText;
      // this.getRunInfoList(this.selectVal);
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //下拉框
    changeSele(item) {
      this.formInline.devicValue = item;
      this.devNameData.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.devicValue;
        }
      });

      this.placeholder = item;
    },

    handleClose() {
      this.outerVisible = false; // 详情关闭按钮
    },
    Day() {
      // 安全态势的跳转
      this.formInline.optionDate1 = this.$route.query.dataDay.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
    },
    Mounth() {
      // 安全态势的跳转
      this.formInline.optionDate1 = this.$route.query.dataMounth.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
    },
    MingTime() {
      // 安全态势的跳转
      this.formInline.keywords = this.$route.query.name;
      this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
      if (
        this.$route.query.starttime == "00" ||
        this.$route.query.starttime == "01" ||
        this.$route.query.starttime == "02" ||
        this.$route.query.starttime == "03" ||
        this.$route.query.starttime == "04" ||
        this.$route.query.starttime == "05" ||
        this.$route.query.starttime == "06" ||
        this.$route.query.starttime == "07" ||
        this.$route.query.starttime == "08" ||
        this.$route.query.starttime == "09" ||
        this.$route.query.starttime == "10" ||
        this.$route.query.starttime == "11" ||
        this.$route.query.starttime == "12" ||
        this.$route.query.starttime == "13" ||
        this.$route.query.starttime == "14" ||
        this.$route.query.starttime == "15" ||
        this.$route.query.starttime == "16" ||
        this.$route.query.starttime == "17" ||
        this.$route.query.starttime == "18" ||
        this.$route.query.starttime == "19" ||
        this.$route.query.starttime == "20" ||
        this.$route.query.starttime == "21" ||
        this.$route.query.starttime == "22" ||
        this.$route.query.starttime == "23" ||
        this.$route.query.starttime == "24"
      ) {
        this.formInline.optionDate1 = this.$common
          .UTCformat(this.getdatatime())
          .split(" ")[0];
        console.log(this.formInline.optionDate1, "第一条时间");
        this.formInline.optionDate2 = this.$common
          .UTCformat(this.getdatatime())
          .split(" ")[0];
        console.log(this.formInline.optionDate2, "截止到当天时间");
      } else {
        this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
        console.log(this.formInline.optionDate1, "第一条时间");
        this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
        console.log(this.formInline.optionDate2, "截止到当天时间");
      }
    },
    card() {
      this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
    },

    getdatatime() {
      // 默认显示当天时间
      return new Date().getTime();
    },

    sort(column) {
      // 排序@sort-change="sort"
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },

    handleParentGetList(page, limit) {
      // 响应分页组件查询事件
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.getRunInfoList(this.selectVal);
    },
    tableTitleTime(i, k) {
      // console.log(i, k);
    },
    changeDate() {
      let start = new Date(this.formInline.optionDate1).getTime();
      let end = new Date(this.formInline.optionDate2).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    //列表
    async getRunInfoList(val) {
      this.changeDate();
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        customKey: val ? val.deviceName : this.seleShift,
        customVal: val ? val.InputText : this.formInline.keywords,
      };
      const res = await this.$axios.post(
        "/httpServe/appRun/getDataInfo",
        parmas,
        true
      );
      this.lists = res.data.content;
      for (var i = 0; i < this.lists.length; i++) {
        // 对于为空的字段，显示N/A
        var obj2 = this.lists[i];

        for (var key in obj2) {
          if (obj2[key] == null) {
            obj2[key] = "N/A";
          }
        }
      }
      this.page.total = res.data.total;
    },
    //查询
    async onSubmit(val) {
      this.selectVal = val;
      if (this.$route.query.card == true) {
        this.card();
      }
      this.page.currentPage = 1;
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        customKey: val.deviceName,
        customVal: val.InputText,
      };
      const res = await this.$axios.post(
        "/httpServe/appRun/getDataInfo",
        parmas,
        true
      );
      this.lists = res.data.content;
      this.page.total = res.data.total;
    },
    //导出
    async outreport() {
      let arrOut = [];
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != "操作") {
          arrOut.push(item.eng);

          this.tradeSelections1 = [...new Set(arrOut)]; //去重
        }
      });

      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMzNzU1NjQsInVzZXJJZCI6MSwiaWF0IjoxNjMyNjU1NTY0fQ.OtDA-uF3NfP8xqj902kqxPu54UPgs8SYVY1nBTl1Ifnvoxw_-MPmcN_DKeYDQXHZfEizztITgMABrO0tBmvshA";
      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      let groupId = JSON.parse(userString).userId;
      this.userId = groupId;
      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      }
      if (this.$route.query.cli == true) {
        this.MingTime();
      }
      if (this.$route.query.card == true) {
        this.card();
      }
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.total,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        type: "",
        level: "",
        location: [],
        devicValue: "",
         
        customKey: this.selectVal.deviceName
          ? this.selectVal.deviceName
          : this.seleShift,
        customVal: this.selectVal.InputText
          ? this.selectVal.InputText
          : this.formInline.keywords,
        exportField: this.tradeSelections1,
      };
      console.log(parmas, "导出parmas");
      const res = await this.$axios.postExport(
        "/httpExport/appRun/exportExcel",
        parmas,
        true,
        this.userId
      );

      const link = document.createElement("a");
      var fileName = "exportAppRun_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 详情
    details(info) {
      this.outerVisible = true;
      this.detailsArray = info;
      // this.detailsArray.decide = eval(this.detailsArray.decide)
      console.log(this.detailsArray, "this.detailsArray");
    },
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "appname" ||
          index == "versionname" ||
          index == "devicename" ||
          index == "loginname" ||
          index == "groupfullname" ||
          index == "runcount" ||
          index == "runtimeStr" ||
          index == "useflow" ||
          index == "uploadtime" ||
          index == "uploadtime"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.common-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
  .elRow {
    margin-bottom: 10px;
  }
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
</style>
